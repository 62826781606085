<template>
  <div>
    <Modal :options="{width: '60vw', close:true, esc:false}" @close="$emit('close')">
      <div class="title">Editar requerimiento</div>
      <div class="body">
        <div class="row form-group">
          <label for="req.nombre" class="col-form-label col-sm-3">Nombre</label>
          <div class="col-sm-9"><input v-model="req.nombre" type="text" name="req.nombre" id="req.nombre" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="req.estatus" class="col-form-label col-sm-3">Estatus</label>
          <div class="col-sm-9">
            <select v-model="req.estatus" name="req.estatus" id="req.estatus" class="form-control">
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12">
            <DataGrid :configuracion="datagrid_config" :data="req.campos" @ready="buscar_btns">
              <button class="btn btn-warning mr-2" @click="cargar_requerimiento">Recargar</button>
              <button class="btn btn-info" @click="modal_nuevo=true">Nuevo campo</button>
            </DataGrid>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="guardar_req">Guardar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar edición</button>
          </div>
        </div>
      </div>
    </Modal>

    <Nuevo v-if="modal_nuevo" @update="agregar_campo" @close="cerrar_modales" />
    <Editar v-if="modal_editar" :campo="campo" @update="actualizar_campo" @close="cerrar_modales" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal';
  import DataGrid from '@/components/DataGridV3';

  import Nuevo from './Campo/Nuevo';
  import Editar from './Campo/Editar';

  import apiArea from '@/apps/tickets/api/areas';

  export default {
    components: {
      Modal, DataGrid, Nuevo, Editar
    }
    ,props: {
      area: {
        type: Object,
        default() {
          return {
            id: null,
            requerimientos: []
          }
        }
      },
      req_id: 0
    }
    ,data() {
      return {
        datagrid_config: {
          name: 'area_reqs'
          ,selector: false
          ,show_info: false
          // ,show_search: false
          ,cols: {
            nombre: 'Nombre'
            ,tipo: 'Tipo campo'
            ,valor_tipo: 'Tipo valor'
            ,requerido: 'Campo requerido'
            ,documentos_requeridos: 'Documentos'
            ,documentos_maximos: 'Número de documentos'
            ,opciones: {
              text: 'Opciones'
              ,ordeable: false
            }
          }
          ,search: ['nombre','tipo','valor_tipo','requerido','documentos_requeridos','documentos_maximos']
          ,mutators: {
            requerido(val) {
              return val == 1 ? 'Sí' : 'No';
            },
            documentos_requeridos(val) {
              return val == 1 ? 'Sí' : 'No';
            },
            documentos_maximos(val) {
              return val != 0 ? val : 'N/A';
            },
            estatus(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,opciones(val, row, vue, index, row_index) {
              return '<div class="text-center">'
                  +  '<img class="mr-2 btn-areas-campo" data-index="'+row_index+'" id="campo_editar_'+vue.$helper.strRand(10)+'" data-action="editar" data-campo="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar campo" title="Editar campo" />'
                  +  '<img class="btn-areas-campo" data-index="'+row_index+'" data-action="eliminar" id="campo_eliminar_'+vue.$helper.strRand(10)+'" data-campo="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar campo" title="Elimitar campo" />'
                  +  '</div>';
            }
          }
        },
        req: {
          nombre: null,
          estatus: 1,
          campos: []
        }
        ,campo: {
          nombre: null,
          tipo: 'text',
          valor: null,
          valor_tipo: 'text',
          requerido: 1,
          documentos_requeridos: 1,
          documentos_maximos: 1
        }
        ,campo_index: null
        ,modal_nuevo: false
        ,modal_editar: false
      }
    }
    ,mounted() {
      this.cargar_requerimiento();
    }
    ,methods: {
      async cargar_requerimiento() {
        try {
          this.req = (await apiArea.obtener_req(this.area.id, this.req_id)).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      buscar_btns() {
        document.querySelectorAll('.btn-areas-campo').forEach(btn => {
          btn.removeEventListener('click', this.acciones_btns);
          btn.addEventListener('click', this.acciones_btns);
        })
      },
      acciones_btns(e) {
        let campo = e.target.dataset.campo;
        campo = this.$helper.fromJson(campo);

        if (e.target.dataset.action == 'editar') {
          this.campo = campo;
          this.modal_editar = true;
          this.campo_index = e.target.dataset.index;
          return;
        }
      },
      async guardar_req() {
        try {
          let req = this.$helper.clear_null_nodes(this.req);
          let res = (await apiArea.editar_req(this.area.id, this.req.id, req)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_modales() {
        this.modal_nuevo = false;
        this.modal_editar = false;
        this.campo = {
          nombre: null,
          tipo: 'text',
          valor: null,
          valor_tipo: 'text',
          requerido: 1,
          documentos_requeridos: 1,
          documentos_maximos: 1
        }
        this.campo_index = null;
      }
      ,actualizar_modal() {
        this.cerrar_modales();

        this.$emit('update');
      }
      ,actualizar_campo(campo) {
        let req = this.$helper.clone(this.req);
        this.req.campos = [];

        this.$log.info('index:'+this.campo_index);
        for(let i=0; i<req.campos.length; i++) {
          if (i == this.campo_index) {
            this.$log.info('se encontró campo a editar', i);
            req.campos[i] = campo;
          }
        }

        setTimeout(() => {
          this.req = req;  
        },100);
        

        this.cerrar_modales();
      }
      ,agregar_campo(campo) {
        this.req.campos.push(campo);
        this.cerrar_modales();
      }
    }
  }
</script>

<style lang="scss">
  .btn-areas-campo {
    width: 20px !important;
  }
</style>